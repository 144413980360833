"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLRoleNames = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLRoleNames = new _enum.default({
  "READER": "READER",
  "UPLOADER": "UPLOADER",
  "EDITOR": "PROFILE",
  "ADMIN": "ADMIN",
  "OWNER": "OWNER"
});
exports.MLRoleNames = MLRoleNames;