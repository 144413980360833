"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserAndAccountStatuses = void 0;

var _enum = _interopRequireDefault(require("enum"));

var UserAndAccountStatuses = new _enum.default({
  "EMAIL_NOT_VERIFIED": "EMAIL_NOT_VERIFIED",
  "EMAIL_VERIFIED": "EMAIL_VERIFIED",
  "TOKEN_EXPIRED": "TOKEN_EXPIRED",
  "NOT_APPROVED": "NOT_APPROVED",
  "APPROVED": "APPROVED"
});
exports.UserAndAccountStatuses = UserAndAccountStatuses;