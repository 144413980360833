"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MangoMenuItems = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MangoMenuItems = new _enum.default({
  USER_SEARCH: "user-search",
  ACCOUNTS: "accounts"
});
exports.MangoMenuItems = MangoMenuItems;