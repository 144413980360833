'use strict';
/**
 * This method determines whether or not a user is successfully logged in
 * by checking store commits
 * @param {Object} store - the store object
 * @param {String} successfulLogin 
 * @param {String} failedLogin 
 * @param {String} loginAction - a string representing the action method that handles logging in
 */

require("core-js/modules/es.array.includes");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.string.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLoggedIn = getLoggedIn;

function getLoggedIn(store, successfulLogin, failedLogin, loginAction) {
  return new Promise(function (resolve, reject) {
    var storeHandler = store.subscribe(function (evt) {
      if (evt.type.includes(successfulLogin)) {
        resolve();
        storeHandler();
      } else if (evt.type.includes(failedLogin)) {
        reject();
        storeHandler();
      }
    });
    store.dispatch(loginAction);
  });
}