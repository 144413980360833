"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocalStorageKeys = void 0;

var _enum = _interopRequireDefault(require("enum"));

var LocalStorageKeys = new _enum.default({
  'LOCAL_STORAGE_EMAIL': 'leoEmailAddress',
  'LOCAL_BRAND_USER_SEARCH_TEXT': 'brandUserSearchText',
  'LOCAL_BRAND_USER_SEARCH_RESULT': 'brandUserSearchResult',
  'LOCAL_ACCOUNT_CONTENT_SEARCH_TEXT': 'accountContentSearchText'
}, {
  ignoreCase: true
});
exports.LocalStorageKeys = LocalStorageKeys;