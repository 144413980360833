'use strict'; //Using require to support using enum in both js and mjs files (ie: UI and API)

require("core-js/modules/es.function.name");

require("core-js/modules/web.dom-collections.for-each");

var Enum = require('enum'),
    _require = require('date-fns'),
    differenceInWeeks = _require.differenceInWeeks;

var Flags = new Enum({});

if (process.env.NODE_ENV !== 'production') {
  Flags.enums.forEach(function (enumItem) {
    var value = enumItem.value,
        dateDiff = differenceInWeeks(new Date(), value.addDate);

    if (dateDiff >= 6) {
      console.error("Flag ".concat(value.name, " has been around for over 6 weeks. Please clean up."));
    } else if (dateDiff >= 4) {
      console.warn("Flag ".concat(value.name, " has been around for over 4 weeks."));
    }
  });
} //Using module.exports to support using enum in both js and mjs files (ie: UI and API)


module.exports = Flags;