'use strict';

require("core-js/modules/es.array.filter");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSearchedAndFilteredList = getSearchedAndFilteredList;

var _filter = require("../filter");

var _searchUtil = require("../search-util");

/**
 * Finds all corresponding items that apply to both the provided filters and the search field.
 * Used when filters and search need to be in sync, with search only searching on filtered items, and filters
 * being further refined when search is applied
 *
 * @param {array} originalList Master list of items
 * @param {array} filters List of filters that are applied on the page
 * @param {array} searchFields List of fields to search on
 * @param {string} searchTerm Search string, the string typed in to the search bar
 */
function getSearchedAndFilteredList(originalList, filters, searchFields, searchTerm) {
  var newList = originalList;
  var updatedFilters = filters;

  for (var i = 0, len = filters.length; i < len; i++) {
    if (filters[i].item.parentLabel) {
      // updatedFilters = updatedFilters.filter(item=> item.item.label !== filters[i].item.parentLabel)
      updatedFilters = updatedFilters.filter(function (item) {
        return item.item.parentLabel !== undefined;
      });
      break;
    }
  } // call apply filter if the user has applied filters


  if (updatedFilters.length) {
    newList = (0, _filter.applyFilter)(originalList, updatedFilters);
  } // call to get search list on list returned from above


  if (searchTerm) {
    newList = (0, _searchUtil.getSearchList)(newList, searchFields, searchTerm);
  }

  return newList;
}