"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLEmailValidateResponses = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLEmailValidateResponses = new _enum.default({
  "EMAIL_NOT_VERIFIED_AND_APPROVED": "EMAIL_NOT_VERIFIED_AND_APPROVED",
  "EMAIL_VERIFIED_AND_NOT_APPROVED": "EMAIL_VERIFIED_AND_NOT_APPROVED",
  "EMAIL_VERIFIED_AND_APPROVED": "EMAIL_VERIFIED_AND_APPROVED",
  "EMAIL_NOT_VERIFIED_AND_NOT_APPROVED": "EMAIL_NOT_VERIFIED_AND_NOT_APPROVED"
});
exports.MLEmailValidateResponses = MLEmailValidateResponses;