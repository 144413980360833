"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLOrientation = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLOrientation = new _enum.default({
  "VERTICAL": "MLASSET_ORIENTATION_VERTICAL",
  "SQUARE": "MLASSET_ORIENTATION_SQUARE",
  "HORIZONTAL": "MLASSET_ORIENTATION_HORIZONTAL",
  "PANORAMIC": "MLASSET_ORIENTATION_PANORAMIC"
}, {
  ignoreCase: true
});
exports.MLOrientation = MLOrientation;