"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialMenuAnchors = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MaterialMenuAnchors = new _enum.default({
  "BOTTOM_END": 13,
  "BOTTOM_LEFT": 1,
  "BOTTOM_RIGHT": 5,
  "BOTTOM_START": 9,
  "TOP_END": 12,
  "TOP_LEFT": 0,
  "TOP_RIGHT": 4,
  "TOP_START": 8
});
exports.MaterialMenuAnchors = MaterialMenuAnchors;