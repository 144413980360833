"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatePickerLayouts = void 0;

var _enum = _interopRequireDefault(require("enum"));

var DatePickerLayouts = new _enum.default(['COMPACT', 'VERTICAL', 'HORIZONTAL']);
exports.DatePickerLayouts = DatePickerLayouts;