"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var LinkTypes = new _enum.default(['TELEPHONE', 'EMAIL', 'FAX', 'HYPERLINK', 'TARGET', "INTERNAL_HYPERLINK"]);
exports.LinkTypes = LinkTypes;