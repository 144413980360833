"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnitCodes = void 0;

var _enum = _interopRequireDefault(require("enum"));

//http://www.datypic.com/sc/ubl20/a-unitCode-3.html
var UnitCodes = new _enum.default({
  ONE: "C62",
  SQUARE_FOOT: "FTK",
  SQUARE_METER: "MTK",
  DAY: "DAY"
});
exports.UnitCodes = UnitCodes;