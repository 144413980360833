"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLUserDrawerActions = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLUserDrawerActions = new _enum.default(['approve-user', 'resend-invite']);
exports.MLUserDrawerActions = MLUserDrawerActions;