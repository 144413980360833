"use strict";

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

module.exports = {
  en: function en() {
    return import(
    /* webpackChunkName: "en" */
    './bundles/lang-bundle-en.json');
  },
  fr: function fr() {
    return import(
    /* webpackChunkName: "fr" */
    './bundles/lang-bundle-fr.json');
  }
};