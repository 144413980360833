'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.object.get-own-property-descriptor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.weak-map");

require("core-js/modules/web.dom-collections.iterator");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserRole = getUserRole;
exports.getUserRoles = getUserRoles;
exports.isNotActive = isNotActive;
exports.retrieveJwtToken = retrieveJwtToken;
exports.setUserState = setUserState;
exports.setUserStateForAccount = setUserStateForAccount;

var _get2 = _interopRequireDefault(require("lodash-es/get"));

var _locale = _interopRequireDefault(require("@lwc-comps/locale"));

require("./locale/locale-map.json");

var _lwcEnums = require("lwc-enums");

var Cookies = _interopRequireWildcard(require("@lwc-ui/services/cookies"));

var SessionStorage = _interopRequireWildcard(require("@lwc-ui/services/session-storage"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var LOCALE_PREFIX_ROLE = "user.role.";
/**
 * Function to get the user role
 *
 * @param {Array} roles assigned to the user
 * @returns {String} userRole will be presented on UI
 */

function getUserRoles(roles) {
  var userRole = "";

  if (Array.isArray(roles)) {
    var numRoles = roles.length;

    if (numRoles === 0) {
      userRole = _locale.default.t("".concat(LOCALE_PREFIX_ROLE, "none"));
    } else if (numRoles === 1) {
      userRole = _locale.default.t("".concat(LOCALE_PREFIX_ROLE).concat(roles[0].roleName));
    } else {
      userRole = _locale.default.t("".concat(LOCALE_PREFIX_ROLE, "more-roles"));
    }
  }

  return userRole;
}
/**
 * Function to get the user role of the account
 *
 * @param {String} role assigned to the user
 * @returns {String} userRole will be presented on UI
 */


function getUserRole(role) {
  var userRole = "";

  if (role) {
    userRole = _locale.default.t("".concat(LOCALE_PREFIX_ROLE).concat(role));
  } else {
    userRole = _locale.default.t("".concat(LOCALE_PREFIX_ROLE, "none"));
  }

  return userRole;
}
/**
 * This method determines and sets the user state based off its account status and user status. There
 * are 4 possible states:
 * 1) If the user's userAccountStatus is approved, it means that they are an approved/active user
 * 2) If the user's userStatus says the email is not verified, it means that they are pending verification
 * 3) If the user's userStatus says the email is verified, but their userAccountStatus is not approved,
 *  it means that the user is pending approval
 * 4) If the user's userStatus says the email token is expired, and userAccountStatus is approved,
 *  it means that the user is invite expired
 * @param {Object} user a user object
 */


function setUserState(user) {
  var userStatus = (0, _get2.default)(user, 'userStatus');

  if (userStatus === _lwcEnums.UserAndAccountStatuses.EMAIL_NOT_VERIFIED.value) {
    user.state = _lwcEnums.UserStates.PENDING_EMAIL_VERIFICATION.value;
  } else if (userStatus === _lwcEnums.UserAndAccountStatuses.EMAIL_VERIFIED.value) {
    user.state = (0, _get2.default)(user, 'accountUserDetails.userAccountStatus') === _lwcEnums.UserAndAccountStatuses.NOT_APPROVED.value ? _lwcEnums.UserStates.PENDING_APPROVAL.value : _lwcEnums.UserStates.APPROVED.value;
  } else if (userStatus === _lwcEnums.UserAndAccountStatuses.TOKEN_EXPIRED.value) {
    user.state = (0, _get2.default)(user, 'accountUserDetails.userAccountStatus') === _lwcEnums.UserAndAccountStatuses.NOT_APPROVED.value ? _lwcEnums.UserStates.PENDING_EMAIL_VERIFICATION.value : _lwcEnums.UserStates.INVITE_EXPIRED.value;
  }
}

function setUserStateForAccount(userAccount, userStatus) {
  if (userStatus === _lwcEnums.UserAndAccountStatuses.EMAIL_NOT_VERIFIED.value) {
    userAccount.state = _lwcEnums.UserStates.PENDING_EMAIL_VERIFICATION.value;
  } else if (userStatus === _lwcEnums.UserAndAccountStatuses.EMAIL_VERIFIED.value) {
    userAccount.state = (0, _get2.default)(userAccount, 'userAccountStatus') === _lwcEnums.UserAndAccountStatuses.NOT_APPROVED.value ? _lwcEnums.UserStates.PENDING_APPROVAL.value : _lwcEnums.UserStates.APPROVED.value;
  } else if (userStatus === _lwcEnums.UserAndAccountStatuses.TOKEN_EXPIRED.value) {
    userAccount.state = (0, _get2.default)(userAccount, 'userAccountStatus') === _lwcEnums.UserAndAccountStatuses.NOT_APPROVED.value ? _lwcEnums.UserStates.PENDING_EMAIL_VERIFICATION.value : _lwcEnums.UserStates.INVITE_EXPIRED.value;
  }
}
/**
 * This function takes in a user's status within an account and determines whether or not the user is active
 * @param {String} userStatus - The user's status in an account
 * @returns {boolean} true if and only if the user is pending verification, pending approval, or whos invite has expired
 */


function isNotActive(userStatus) {
  return userStatus === _lwcEnums.UserStates.PENDING_EMAIL_VERIFICATION.value || userStatus === _lwcEnums.UserStates.PENDING_APPROVAL.value || userStatus === _lwcEnums.UserStates.INVITE_EXPIRED.value;
}
/**
 * This function retrieves JWT token from Cookies or Session storage
 * @returns {String} Token value. Returns empty string if the token wasn't retrieved
 */


function retrieveJwtToken() {
  var tokenValue = '';
  var cookieJWT = Cookies.get(_lwcEnums.TokenTypes.JWT.value),
      emulationJWT = SessionStorage.read(_lwcEnums.TokenTypes.JWT_EMULATION.value);

  if (cookieJWT || emulationJWT) {
    // Emulation token is prioritised, since if present it's an emulation session
    tokenValue = emulationJWT ? emulationJWT : cookieJWT;
  }

  return tokenValue;
}