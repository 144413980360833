"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLUserTextFields = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLUserTextFields = new _enum.default({
  "FIRST_NAME": "firstName",
  "LAST_NAME": "lastName",
  "EMAIL_ADDRESS": "emailAddress",
  "COMPANY_NAME": "companyName",
  "PHONE_NUMBER": "phoneNumber",
  "CURRENT_PASSWORD": "currentPassword",
  "NEW_PASSWORD": "newPassword",
  "CONFIRM_PASSWORD": "confirmPassword"
});
exports.MLUserTextFields = MLUserTextFields;