"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextFieldVariants = void 0;

var _enum = _interopRequireDefault(require("enum"));

var TextFieldVariants = new _enum.default({
  "FILLED": "filled",
  "OUTLINED": "outlined",
  "FULLWIDTH": "fullwidth",
  "TEXTAREA": "textarea",
  "DISABLED": "disabled"
});
exports.TextFieldVariants = TextFieldVariants;