"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAWidgets = void 0;

var _enum = _interopRequireDefault(require("enum"));

/**
 * TripAdvisor Widgets 
 */
var TAWidgets = new _enum.default({
  SNIPPETS: "selfserveprop",
  STARTER: "cdswritereviewlg"
});
exports.TAWidgets = TAWidgets;