'use strict';
/**
 * Retieve information about the account
 * 
 * @param {string} oidAccount - the account oid
 * @returns {string} The query
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default(oidAccount) {
  return "\n        query{\n            Account(oidAccountRequest:{oidAccount:\"".concat(oidAccount, "\"}){\n                oidAccount\n                accountName\n                accountLogicalName\n                vscapeOidIdsClient\n                accountStatus\n                autoApproveUsers\n                mediaLibraryConfig {\n                    oidPrimaryLogo\n                    oidSecondaryLogo\n                    oidMediaLibraryConfig\n                    oidLoginLogo\n                    accountStyles\n                }\n            }\n        }\n    ");
};

exports.default = _default;
module.exports = exports.default;