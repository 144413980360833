"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MediaTypes = new _enum.default({
  IMAGE: "IMAGE_ASSET",
  VIDEO: "VIDEO_ASSET",
  VIRTUAL_TOUR: "VIRTUAL_TOUR_ASSET",
  DOCUMENT: "DOCUMENT_ASSET"
}, {
  ignoreCase: true
});
exports.MediaTypes = MediaTypes;