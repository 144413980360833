"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadDirection = void 0;

var _enum = _interopRequireDefault(require("enum"));

var ReadDirection = new _enum.default({
  LEFT_TO_RIGHT: "ltr",
  RIGHT_TO_LEFT: "rtl"
});
exports.ReadDirection = ReadDirection;