"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticCategories = void 0;

var _enum = _interopRequireDefault(require("enum"));

var AnalyticCategories = new _enum.default({
  'VIZLLY_LEAD': 'Vizlly Lead',
  'OFFLINE_LEAD': 'Offline Lead'
});
exports.AnalyticCategories = AnalyticCategories;