"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Range = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Range = new _enum.default(['MIN', 'MAX']);
exports.Range = Range;