"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Layouts = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Layouts = new _enum.default({
  'IMAGE_LEFT': 'image-left',
  'IMAGE_RIGHT': 'image-right',
  'REVERSE_ROWS': 'reverse-rows',
  'TWO_COLUMN': 'two-column',
  'THREE_COLUMN': 'three-column',
  'FULL_WIDTH': 'full-width'
}, {
  ignoreCase: true
});
exports.Layouts = Layouts;