"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.write = exports.remove = exports.read = exports.each = exports.clearAll = void 0;

var _sessionStorage = _interopRequireDefault(require("store/storages/sessionStorage"));

/**
 * This is a wrapper for session storage
 */

/**
 * Get key data from the session storage
 * @param {string} key
 * @returns {Object}
 */
var read = function read(key) {
  return _sessionStorage.default.read(key);
};
/**
 * Set data with the key in session storage
 * @param {string} key
 * @param {*} data
 */


exports.read = read;

var write = function write(key, data) {
  _sessionStorage.default.write(key, data);
};
/**
 * Removes key in session storage
 * @param {string} key
 */


exports.write = write;

var remove = function remove(key) {
  _sessionStorage.default.remove(key);
};
/**
 * For each key data run against the callback funtion, with argumet order - (data, key)
 * @callback callbackFn
 * @param {*} data
 * @param {string} key
 */


exports.remove = remove;

var each = function each(callbackFn) {
  _sessionStorage.default.each(callbackFn);
};
/**
 * Clears session storage
 */


exports.each = each;

var clearAll = function clearAll() {
  _sessionStorage.default.clearAll();
};

exports.clearAll = clearAll;