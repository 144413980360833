"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var TokenTypes = new _enum.default({
  JWT: "jwt",
  JWT_EMULATION: "jwt_emulation",
  JWT_NOTIFICATION: "jwt_notification"
});
exports.TokenTypes = TokenTypes;