"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MimeTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MimeTypes = new _enum.default({
  CSV: 'text/csv',
  JPEG: 'image/jpeg'
});
exports.MimeTypes = MimeTypes;