"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MangoAccountAutoApproveOptions = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MangoAccountAutoApproveOptions = new _enum.default({
  "MANUALLY_APPROVE_USERS": false,
  "AUTO_APPROVE_USERS": true
});
exports.MangoAccountAutoApproveOptions = MangoAccountAutoApproveOptions;