"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Positions = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Positions = new _enum.default(["TOP", "BOTTOM", "LEFT", "RIGHT"], {
  ignoreCase: true
});
exports.Positions = Positions;