"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

//import {ContentTypes} from 'lwc-enums';
var ContentTypes = new _enum.default({
  'VIRTUAL_TOUR': "vr_content",
  'VIDEO': 'video_content',
  'IMAGE': 'image_content',
  'OFFER_ITEM': 'offer_story_item',
  'ROOM_ITEM': 'room_type_story_item',
  'GROUPED_FEATURE_STORY': 'galleryTab',
  'GROUPED_ROOM_STORY': 'room_type_collection',
  'GROUPED_OFFER_STORY': 'offer_collection',
  'MULTI_GROUPED_FEATURE_STORY': 'gallery',
  'MULTI_GROUPED_ROOM_STORY': 'room_type_super_collection',
  'MULTI_GROUPED_OFFER_STORY': 'offer_super_collection',
  'CUSTOM_HTML': 'customHtml',
  'BRAND_BADGES': 'brand_badges'
});
exports.ContentTypes = ContentTypes;