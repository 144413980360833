'use strict'; //Queries

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "accountQuery", {
  enumerable: true,
  get: function get() {
    return _account.default;
  }
});
Object.defineProperty(exports, "loginConfigQuery", {
  enumerable: true,
  get: function get() {
    return _loginConfig.default;
  }
});
Object.defineProperty(exports, "termsAndConditionsQuery", {
  enumerable: true,
  get: function get() {
    return _termsAndConditions.default;
  }
});

var _account = _interopRequireDefault(require("@lwc-api/ui-queries/account/queries/account"));

var _loginConfig = _interopRequireDefault(require("./queries/login-config"));

var _termsAndConditions = _interopRequireDefault(require("./queries/terms-and-conditions"));