"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

/**
 * This is a facade around the i18next implementation
 * this is so we can swap out i18next in the future if we want
 */

/**
 *
 * used to initialize the locale
 * wrapping the function init here: https://www.i18next.com/overview/api#init
 * @param {*} options:
 *
 * return a promise
 */
var init = function init(options) {
  //init locale and return a promise
  var promise = new Promise(function (resolve) {
    //https://www.i18next.com/overview/api#init
    _i18next.default.init(options, function () {
      //lang initialized
      resolve();
    });
  });
  return promise;
};
/**
 *
 * @param {*} key: key to the location of the translation
 * @param {*} options
 */


var t = function t(key, options) {
  //https://www.i18next.com/overview/api#t
  return _i18next.default.t(key, options);
};
/**
 * Used to detected initialized language
 */


var language = function language() {
  //https://www.i18next.com/overview/api#language
  return _i18next.default.language;
};

var _default = {
  init: init,
  t: t,
  language: language
};
exports.default = _default;
module.exports = exports.default;