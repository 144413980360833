"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticEvents = void 0;

var _enum = _interopRequireDefault(require("enum"));

var AnalyticEvents = new _enum.default({
  'PHONE': 'Phone',
  'EMAIL': 'Email',
  'WIDGET': 'Widget',
  'ROOM': 'Room',
  'OFFER': 'Offer'
});
exports.AnalyticEvents = AnalyticEvents;