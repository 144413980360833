"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkRelationships = void 0;

var _enum = _interopRequireDefault(require("enum"));

var LinkRelationships = new _enum.default({
  'NO_FOLLOW': 'nofollow'
});
exports.LinkRelationships = LinkRelationships;