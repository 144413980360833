'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

require("idempotent-babel-polyfill");

var _locale = _interopRequireDefault(require("@lwc-comps/locale"));

var _account = require("@ml-api/client/ui-queries/account");

var _client = _interopRequireDefault(require("@ml-ui/services/client"));

var _utils = require("@lwc-ui/utils");

var _flags = require("@ml-api/client/ui-queries/flags");

//TODO:load localization
var init = function init() {
  var accountName = window.location.pathname.split("/")[1],
      queryStr = (0, _account.loginConfigQuery)(accountName),
      bundleMap = require('@lwc-comps/locale/src/lang-map.json'),
      promises = [(0, _utils.initLangBundle)(_locale.default, 'en', bundleMap), _client.default.query(queryStr), _client.default.query((0, _flags.flagsQuery)())];

  Promise.all(promises).then(function (returnValues) {
    window.__lwcAppConfig = {
      overrides: {
        "primaryColor": returnValues[1].LoginConfig.mediaLibraryConfig.accountStyles.primaryColor
      },
      config: {
        endpoint: "url"
      },
      account: returnValues[1].LoginConfig
    };
    window.__flags = returnValues[2].Flags;
    import('./app-entry'
    /* webpackChunkName: "app" */
    ).then(function () {
      console.log('entry loaded');
    });
  }).catch(function (error) {
    console.error(error);
    window.__lwcAppConfig = {
      "error": error
    };
    import('./app-entry'
    /* webpackChunkName: "app" */
    ).then(function () {
      console.log('entry loaded with error');
    });
  });
};

init();