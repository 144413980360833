"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MangoUserTextFields = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MangoUserTextFields = new _enum.default({
  "FIRST_NAME": "firstName",
  "LAST_NAME": "lastName",
  "EMAIL_ADDRESS": "emailAddress",
  "COMPANY_NAME": "companyName",
  "OWNER": "name"
});
exports.MangoUserTextFields = MangoUserTextFields;