'use strict';

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default(accountLogicalName) {
  var commonAssetTypes = "\n        sourcePath\n    ";
  return "\n        query{\n            LoginConfig(\n                accountLoginConfigRequest:{accountLogicalName:\"".concat(accountLogicalName, "\"}){\n                oidAccount,\n                accountName,\n                mediaLibraryConfig{\n                    accountStyles,\n                },\n                primaryLogoAsset{\n                    ").concat(commonAssetTypes, "\n                },\n                secondaryLogoAsset{\n                    ").concat(commonAssetTypes, "\n                },\n                loginImageAsset{\n                    ").concat(commonAssetTypes, "\n                },\n            }\n        }\n    ");
};

exports.default = _default;
module.exports = exports.default;