"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createModelsFromObject = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _hotelView = _interopRequireDefault(require("../hotel/hotel-view"));

var _content = _interopRequireDefault(require("../content/content"));

/* create mappting of graphQL return types with the class type that they are */
var typeMap = {
  HotelViewList: _hotelView.default,
  HotelView: _hotelView.default,
  Content: _content.default,
  HotelContentList: _content.default,
  AccountContentList: _content.default,
  CollectionContentList: _content.default
};
/* TODO: use the object key to determine which object type it is */

var createModelsFromObject = function createModelsFromObject(object) {
  var keys = Object.keys(object);

  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];

    if ((0, _typeof2.default)(object[key]) === 'object') {
      var Clazz = typeMap[key];

      if (Clazz) {
        return convertToModels(object[key], Clazz);
      } else {
        return object;
      }
    }
  }

  return object;
};

exports.createModelsFromObject = createModelsFromObject;

var convertToModels = function convertToModels(data, Clazz) {
  if (Array.isArray(data)) {
    var arrayOfModels = [];
    var len = data.length;

    for (var x = 0; x < len; x++) {
      arrayOfModels.push(new Clazz(data[x]));
    }

    return arrayOfModels;
  } else {
    return new Clazz(data);
  }
};