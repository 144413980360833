'use strict';
/**
 * Should initialize the locale for an application
 * 
 * @param {object} locale - the locale instance for an app
 * @param {string} lang - the init language ie: en
 * @param {object} bundleMap - a map of language bundles
 * 
 * @returns {Promise} a promise when initialization is completed
 */

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _default = function _default(locale, lang, bundleMap) {
  var promise = new Promise(function (resolve, reject) {
    var initLocale = function initLocale(bundle) {
      locale.init({
        lng: lang,
        debug: false,
        resources: (0, _defineProperty2.default)({}, lang, {
          translation: bundle
        })
      }).then(function () {
        resolve(bundle);
      }).catch(function (error) {
        reject(error);
      });
    }; //get bundle map 
    //using the bundle loader ie: lang_bundle_loader, this is being used in webpack


    if (bundleMap[lang] != null) {
      bundleMap[lang]().then(function (payload) {
        initLocale(payload);
      });
    } else {
      reject("No language bundle for ".concat(lang));
    }
  });
  return promise;
};

exports.default = _default;
module.exports = exports.default;