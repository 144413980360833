"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLUserDrawerTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLUserDrawerTypes = new _enum.default({
  "CREATE_USER": "CREATE_USER",
  "EDIT_USER": "EDIT_USER",
  "PROFILE": "PROFILE"
});
exports.MLUserDrawerTypes = MLUserDrawerTypes;