"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * Query used for all flag requests
 */
var _default = function _default() {
  return "\n        query {\n            Flags\n        }\n    ";
};

exports.default = _default;
module.exports = exports.default;