"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MangoSnackBarErrors = void 0;

var _enum = _interopRequireDefault(require("enum"));

var _grpcErrors = require("../../grpc/grpcErrors.js");

var MangoSnackBarErrors = new _enum.default({
  'SNACKBAR_INVALID_ARGUMENT': _grpcErrors.GrpcErrors[3].value,
  'SNACKBAR_NOT_FOUND': _grpcErrors.GrpcErrors[5].value,
  'SNACKBAR_ALREADY_EXISTS': _grpcErrors.GrpcErrors[6].value,
  'SNACKBAR_PERMISSION_DENIED': _grpcErrors.GrpcErrors[7].value,
  'SNACKBAR_FAILED_PRECONDITION': _grpcErrors.GrpcErrors[9].value
});
exports.MangoSnackBarErrors = MangoSnackBarErrors;