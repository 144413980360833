"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpMethods = void 0;

var _enum = _interopRequireDefault(require("enum"));

var HttpMethods = new _enum.default({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
});
exports.HttpMethods = HttpMethods;