"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeroCarouselDisplay = void 0;

var _enum = _interopRequireDefault(require("enum"));

var HeroCarouselDisplay = new _enum.default({
  SHOW_MEDIA_CAPTION: "showMediaCaption",
  SHOW_PROPERTY_NAME: "showPropertyName",
  HIDE_ALL: "hideAll"
});
exports.HeroCarouselDisplay = HeroCarouselDisplay;