"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DownloadMethods = void 0;

var _enum = _interopRequireDefault(require("enum"));

var DownloadMethods = new _enum.default({
  BYTESTRING: 'ByteString',
  URL: 'URL',
  ARRAY: 'Array'
});
exports.DownloadMethods = DownloadMethods;