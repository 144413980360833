"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLUserActionTimeOuts = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLUserActionTimeOuts = new _enum.default({
  "SIX_HUNDRED_MS": 600
});
exports.MLUserActionTimeOuts = MLUserActionTimeOuts;