"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountMenuActions = void 0;

var _enum = _interopRequireDefault(require("enum"));

var AccountMenuActions = new _enum.default({
  "EDIT_ACCOUNT": "EDIT_ACCOUNT"
});
exports.AccountMenuActions = AccountMenuActions;