/**
 * TODO: Add a @desc for base
 *
 * @author  jason.macdonald
 * @created 2017-08-30
 */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _isObject2 = _interopRequireDefault(require("lodash-es/isObject"));

var _isString2 = _interopRequireDefault(require("lodash-es/isString"));

var _isFunction2 = _interopRequireDefault(require("lodash-es/isFunction"));

var _isArray2 = _interopRequireDefault(require("lodash-es/isArray"));

var _transform2 = _interopRequireDefault(require("lodash-es/transform"));

//FIXME - break out functions that abstracts lodash instead of copy paste code

/**
 * Returns a transform function to be used with lodash's transform
 *
 * @param template
 * @returns {function(*=, *=, *=)}
 */
function transformFunction(template) {
  var _this = this;

  return function (result, value, key) {
    if (template.hasOwnProperty(key)) {
      var val = template[key];

      switch (true) {
        case (0, _isArray2.default)(val):
          {
            var _val = (0, _slicedToArray2.default)(val, 2),
                k = _val[0],
                tpl = _val[1];

            if (!tpl) {
              // map children onto parent object and apply transform
              (0, _transform2.default)(value, transformFunction(k), result);
            } else if ((0, _isFunction2.default)(tpl)) {
              result[k] = tpl.call(_this, value, key, k);
            } else {
              // change parent key and transform children
              result[k] = (0, _transform2.default)(value, transformFunction(tpl), {});
            }

            break;
          }

        case (0, _isString2.default)(val):
          // remap key
          result[val] = value;
          break;

        case (0, _isFunction2.default)(val):
          if ((0, _isArray2.default)(value)) {
            result[key] = value.map(function (item) {
              return val.call(_this, item, key, null);
            });
          } else {
            // call function on value, leave key
            result[key] = val.call(_this, value, key, null);
          }

          break;

        case (0, _isObject2.default)(val):
          if ((0, _isArray2.default)(value)) {
            // map children, leave key
            result[key] = value.map(function (item) {
              return (0, _transform2.default)(item, transformFunction(val), {});
            });
          } else {
            // map children, leave key
            result[key] = (0, _transform2.default)(value, transformFunction(val), {});
          }

          break;

        default:
          // key: false
          // don't map the value onto the new object, omit it
          break;
      }
    } else {
      // just map the key:value without touching anything
      result[key] = value;
    }
  };
}

function _default(data, template) {
  var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (!data) {
    return obj;
  }

  if (template) {
    (0, _transform2.default)(data, transformFunction(template), obj);
  } else {
    Object.apply(obj, data);
  }

  return obj;
}

module.exports = exports.default;