/**
 * @author  Weina Liu
 * @created 2019-02-25
 */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatLastSeenDate = formatLastSeenDate;

var _locale = _interopRequireDefault(require("@lwc-comps/locale"));

require("./locale/locale-map.json");

var _dateFns = require("date-fns");

var LOCALE_PREFIX = 'date-formatter.last-seen.';
/**
 * Utility to format the last seen label
 * @param {String} date String of last login time stamp in format "Month Day, Year"
 * e.g. "Jan 23, 2019"
 * @returns {String} String of last seen in words
 */

function formatLastSeenDate(date) {
  date = Date.parse(date);

  if (!isNaN(date)) {
    return (0, _dateFns.formatDistanceToNow)(date, {
      locale: {
        formatDistance: localize
      }
    });
  } else {
    return _locale.default.t("".concat(LOCALE_PREFIX, "never"));
  }
}
/**
 * Function to read locale file
 * @param {String} token distance in words to now, which is provided from the date-fns library
 * @param {integer} count A number that provided with the measurement of time chosen (e.g. "3" days, "5" months, "4" hours)
 * @returns {String} String of last seen in words
 */


function localize(token, count) {
  var localeOption = count === 1 ? {
    count: 1
  } : {
    count: count
  };
  return _locale.default.t("".concat(LOCALE_PREFIX).concat(token), localeOption);
}