"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Directions = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Directions = new _enum.default(['UP', 'DOWN', 'LEFT', 'RIGHT', 'CENTER'], {
  ignoreCase: true
});
exports.Directions = Directions;