"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EncodingTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var EncodingTypes = new _enum.default({
  'VIDEO': 'video',
  'IMAGE': 'image'
});
exports.EncodingTypes = EncodingTypes;