"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageRatios = void 0;

var _enum = _interopRequireDefault(require("enum"));

var ImageRatios = new _enum.default({
  "ONE_BY_ONE": "lcp-image-is-1by1",
  "TWO_BY_ONE": "lcp-image-is-2by1",
  "THREE_BY_TWO": "lcp-image-is-3by2",
  "FOUR_BY_THREE": "lcp-image-is-4by3",
  "SIXTEEN_BY_NINE": "lcp-image-is-16by9"
});
exports.ImageRatios = ImageRatios;