"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserStates = void 0;

var _enum = _interopRequireDefault(require("enum"));

var UserStates = new _enum.default({
  "PENDING_EMAIL_VERIFICATION": "PENDING_EMAIL_VERIFICATION",
  "PENDING_APPROVAL": "PENDING_APPROVAL",
  "APPROVED": "APPROVED",
  "INVITE_EXPIRED": "INVITE_EXPIRED"
});
exports.UserStates = UserStates;