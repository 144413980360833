"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLUserErrors = void 0;

var _enum = _interopRequireDefault(require("enum"));

var _grpcErrors = require("../../grpc/grpcErrors.js");

var MLUserErrors = new _enum.default({
  // Login
  'LOGIN_FAILED': _grpcErrors.GrpcErrors[16].value,
  // Token Verification
  'VERIFY_EMAIL_TOKEN_INVALID_ARGUMENT': _grpcErrors.GrpcErrors[3].value,
  'VERIFY_EMAIL_TOKEN_NOT_FOUND': _grpcErrors.GrpcErrors[5].value,
  'VERIFY_EMAIL_TOKEN_ALREADY_EXISTS': _grpcErrors.GrpcErrors[6].value,
  'VERIFY_EMAIL_TOKEN_PERMISSION_DENIED': _grpcErrors.GrpcErrors[7].value,
  'VERIFY_EMAIL_TOKEN_FAILED_PRECONDITION': _grpcErrors.GrpcErrors[9].value,
  'VERIFY_SELF_REGISTRATION_EMAIL_TOKEN_FAILED': _grpcErrors.GrpcErrors[16].value,
  'VERIFY_INVITE_EMAIL_TOKEN_FAILED': _grpcErrors.GrpcErrors[16].value,
  'VERIFY_FORGOT_PASSWORD_EMAIL_TOKEN_FAILED': _grpcErrors.GrpcErrors[5].value,
  // Forgot Password Token Verification
  'VERIFY_FORGOT_PASSWORD_TOKEN_INVALID_ARGUMENT': _grpcErrors.GrpcErrors[3].value,
  'VERIFY_FORGOT_PASSWORD_TOKEN_NOT_FOUND': _grpcErrors.GrpcErrors[5].value,
  'VERIFY_FORGOT_PASSWORD_TOKEN_ALREADY_EXISTS': _grpcErrors.GrpcErrors[6].value,
  'VERIFY_FORGOT_PASSWORD_TOKEN_PERMISSION_DENIED': _grpcErrors.GrpcErrors[7].value,
  'VERIFY_FORGOT_PASSWORD_TOKEN_FAILED_PRECONDITION': _grpcErrors.GrpcErrors[9].value
});
exports.MLUserErrors = MLUserErrors;