'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.string.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyFilter = applyFilter;

var _get2 = _interopRequireDefault(require("lodash-es/get"));

/*
 * Picks out the items from list based on filterList.
 *
 * OR operator between categories.
 * AND operator across categories.
 *
 * @param {[]} list- list of items
 * @param {SelectionItems[]} filterList - list of SelectionItems
 * @param {function} callback - optional callback for items that get filtered out
 * @returns filtered list
 */
function applyFilter(list, filterList) {
  var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  if (!Array.isArray(list) || !Array.isArray(filterList)) {
    return [];
  }

  var filterLabels = _selectionListToFilterObject(filterList);

  return list.filter(function (item) {
    for (var key in filterLabels) {
      var value = (0, _get2.default)(item, key, "");

      if (!filterLabels[key].includes(value)) {
        if (callback) {
          callback(item);
        }

        return false;
      }
    }

    return true;
  });
}
/**
 * Transforms the selection list into a nicer filter object.
 *
 * @private
 *
 * @params {SelectionItems[]} filterList - an array of Selection Items
 *
 * @returns Object - where each key is an obj property and its value is an
 *                   array of filter values for that property
 */


function _selectionListToFilterObject(filterList) {
  var filterLabels = {}; //TODO: may have to add an extra field if the UI label is different
  //from the data field label.

  for (var i = 0, len = filterList.length; i < len; i++) {
    var filterItem = filterList[i].item,
        filterSet = filterLabels[filterItem.filterProperty];

    if (!filterSet) {
      filterSet = filterLabels[filterItem.filterProperty] = [];
    }

    filterSet.push(filterItem.label);
  }

  return filterLabels;
}