"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkTargets = void 0;

var _enum = _interopRequireDefault(require("enum"));

var LinkTargets = new _enum.default(['SELF', 'PARENT', 'TOP', 'BLANK']);
exports.LinkTargets = LinkTargets;