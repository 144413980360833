"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLEmailNotificationSettings = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLEmailNotificationSettings = new _enum.default({
  "RECEIVE_EMAIL_DECISION_NULL": "RECEIVE_EMAIL_DECISION_NULL",
  "RECEIVE_EMAIL_DECISION_RECEIVE_ALL": "RECEIVE_EMAIL_DECISION_RECEIVE_ALL",
  "RECEIVE_EMAIL_DECISION_REJECT_ALL": "RECEIVE_EMAIL_DECISION_REJECT_ALL",
  "RECEIVE_EMAIL_DECISION_SPECIFIC": "RECEIVE_EMAIL_DECISION_SPECIFIC"
});
exports.MLEmailNotificationSettings = MLEmailNotificationSettings;