"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLMenuItems = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLMenuItems = new _enum.default({
  PROPERTIES: "properties",
  MEDIA: "media",
  COLLECTIONS: "collections",
  ADMIN: "admin",
  REPORTS: "reports",
  PROFILE: "profile",
  LOGOUT: "logout"
});
exports.MLMenuItems = MLMenuItems;