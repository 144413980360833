'use strict'; //Queries

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "flagsQuery", {
  enumerable: true,
  get: function get() {
    return _flags.default;
  }
});

var _flags = _interopRequireDefault(require("@lwc-api/ui-queries/flags/queries/flags"));