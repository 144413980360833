'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default(accountId) {
  return "\n        query {\n            TermsAndConditions(oidAccount:\"".concat(accountId, "\") {\n                leonardo{\n                    text\n                },\n                account{\n                    text\n                }\n            }\n        }\n    ");
};

exports.default = _default;
module.exports = exports.default;