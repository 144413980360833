"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SchemaOrgTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var SchemaOrgTypes = new _enum.default({
  CONTEXT: "http://schema.org",
  HOTEL: "Hotel",
  ADDRESS: "PostalAddress",
  GEO: "GeoCoordinates",
  LANGUAGE: "Language",
  LOCATION_FEATURE: "LocationFeatureSpecification",
  ITEM_LIST: "ItemList",
  LIST_ITEM: "ListItem",
  HOTEL_ROOM: "HotelRoom",
  PRODUCT: "Product",
  OFFER: "Offer",
  IMAGE_OBJECT: "ImageObject",
  VIDEO_OBJECT: "VideoObject",
  WEB_SITE: "WebSite",
  WEB_PAGE: "WebPage",
  CONTACT_PAGE: "ContactPage",
  ITEM_PAGE: "ItemPage",
  ORGANIZATION: "Organization",
  RESERVE_ACTION: "ReserveAction",
  ENTRY_POINT: "EntryPoint",
  RESERVATION: "Reservation",
  QUANTITATIVE_VALUE: "QuantitativeValue",
  BED_DETAILS: "BedDetails",
  UNIT_PRICE_SPECIFICATION: "UnitPriceSpecification",
  //Boolean
  TRUE: "http://schema.org/True",
  FALSE: "http://schema.org/False",
  //Platforms
  DESKTOP: "http://schema.org/DesktopWebPlatform",
  IOS: "http://schema.org/IOSPlatform",
  ANDROID: "http://schema.org/AndroidPlatform",
  //Room Types
  TOTAL_ROOMS: "TotalRooms",
  TOTAL_ACCESSIBLE_ROOMS: "TotalAccessibleRooms",
  TOTAL_MEETING_ROOMS: "TotalMeetingRooms"
});
exports.SchemaOrgTypes = SchemaOrgTypes;