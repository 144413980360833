"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Pagination = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Pagination = new _enum.default(['NEXT', 'PREVIOUS']);
exports.Pagination = Pagination;