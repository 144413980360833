"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Size = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Size = new _enum.default({
  "SMALL": "small",
  "LARGE": "large",
  "EXTRA_SMALL": "extra-small"
}, {
  ignoreCase: true
});
exports.Size = Size;