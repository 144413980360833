"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Orientation = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Orientation = new _enum.default(["HORIZONTAL", "VERTICAL"], {
  ignoreCase: true
});
exports.Orientation = Orientation;