"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var PageTypes = new _enum.default({
  "ROOMS": "rooms",
  "ROOM_DETAILS": "room_details",
  "OFFERS": "offers",
  "OFFER_DETAILS": "offer_details",
  "HOME": "home",
  "FEATURE": "feature",
  "LOCATION": "location",
  "TEXT": "text",
  "REVIEWS": "reviews"
});
exports.PageTypes = PageTypes;