"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Border = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Border = new _enum.default(["NONE", "SOLID"], {
  ignoreCase: true
});
exports.Border = Border;