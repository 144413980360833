"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Keys = void 0;

var _enum = _interopRequireDefault(require("enum"));

var Keys = new _enum.default({
  'ESCAPE': 27,
  'LEFTARROW': 37,
  'RIGHTARROW': 39,
  'ENTER': 13
});
exports.Keys = Keys;