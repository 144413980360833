"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var TextTypes = new _enum.default({
  TEXT: "text",
  PASSWORD: "password"
});
exports.TextTypes = TextTypes;