/**
 * @author  Weina Liu
 * @created 2019-01-31
 */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSearchList = getSearchList;

var _isString2 = _interopRequireDefault(require("lodash-es/isString"));

var _isInteger2 = _interopRequireDefault(require("lodash-es/isInteger"));

var _get2 = _interopRequireDefault(require("lodash-es/get"));

/**
 * Utility to get search list
 * @param {Array} originalList Array of objects
 * @param {Array} searchFields Array of strings of fields used for search
 * @param {String} searchText String used for search
 * @returns {Array} searchList Array of matching objects
 */
function getSearchList(originalList, searchFields, searchText) {
  var searchList = [];

  if (Array.isArray(originalList) && Array.isArray(searchFields) && (0, _isString2.default)(searchText)) {
    searchText = searchText.toLowerCase();
    searchList = originalList.filter(function (item) {
      return searchMatchedItem(item, searchFields, searchText);
    });
  }

  return searchList;
}
/**
 * Return if search text is in provided search fields of the item
 * @param {Object} item Object in the originalList
 * @param {Array} searchFields Array of strings of fields used for search
 * @param {String} searchText String converted to lowercase used for search
 * @returns {Boolean} matched item
 */


function searchMatchedItem(item, searchFields, searchText) {
  var isMatchedItem = false;
  var searchField;

  for (var i = 0, len = searchFields.length; i < len; i++) {
    searchField = searchFields[i];

    if ((0, _isString2.default)((0, _get2.default)(item, searchField, null))) {
      isMatchedItem = (0, _get2.default)(item, searchField, null).toLowerCase().includes(searchText);
    } else if ((0, _isInteger2.default)((0, _get2.default)(item, searchField, null))) {
      isMatchedItem = (0, _get2.default)(item, searchField, null).toString().toLowerCase().includes(searchText);
    } else if (Array.isArray(searchField)) {
      var fullString = "";

      for (var j = 0, _len = searchField.length; j < _len; j++) {
        fullString = fullString + " " + (0, _get2.default)(item, searchField[j], "");
      }

      isMatchedItem = fullString.toLowerCase().includes(searchText);
    }

    if (isMatchedItem) {
      break;
    }
  }

  return isMatchedItem;
}