"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var DocumentTypes = new _enum.default({
  CSV: '.csv',
  PDF: '.pdf',
  JPG: '.jpg',
  JPEG: '.jpeg'
});
exports.DocumentTypes = DocumentTypes;