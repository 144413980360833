"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLMediaDetailsErrors = void 0;

var _enum = _interopRequireDefault(require("enum"));

var _grpcErrors = require("../../grpc/grpcErrors.js");

var MLMediaDetailsErrors = new _enum.default({
  'MEDIA_DETAILS_INVALID_ARGUMENT': _grpcErrors.GrpcErrors[3].value,
  'MEDIA_DETAILS_NOT_FOUND': _grpcErrors.GrpcErrors[5].value,
  'MEDIA_DETAILS_ALREADY_EXISTS': _grpcErrors.GrpcErrors[6].value,
  'MEDIA_DETAILS_PERMISSION_DENIED': _grpcErrors.GrpcErrors[7].value,
  'MEDIA_DETAILS_FAILED_PRECONDITION': _grpcErrors.GrpcErrors[9].value
});
exports.MLMediaDetailsErrors = MLMediaDetailsErrors;