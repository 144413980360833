"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialButtonTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MaterialButtonTypes = new _enum.default({
  "RAISED": "raised",
  "UNELEVATED": "unelevated",
  "OUTLINED": "outlined",
  "DENSE": "dense",
  "NORMAL": "normal",
  "FLAT": "flat"
});
exports.MaterialButtonTypes = MaterialButtonTypes;