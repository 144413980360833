"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MangoAccountDrawerFields = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MangoAccountDrawerFields = new _enum.default({
  "ACCOUNT_NAME": "accountName",
  "VSCAPE_ID": "vscapeId",
  "ACCOUNT_LOGICAL_NAME": "accountLogicalName",
  "PRIMARY_COLOR": "primaryColor",
  "SECONDARY_COLOR": "secondaryColor",
  "LOGO_A_URL": "logoAUrl",
  "LOGO_B_URL": "logoBUrl",
  "FEATURE_IMAGE_URL": "featureImageUrl",
  "LOGO_A_SELECTED": "logoASelected",
  "LOGO_B_SELECTED": "logoBSelected",
  "FEATURE_IMAGE_SELECTED": "featureImageSelected",
  "OWNER": "owner",
  "AUTO_APPROVE_USERS": "autoApproveUsers"
});
exports.MangoAccountDrawerFields = MangoAccountDrawerFields;