"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MLConfigImageType = void 0;

var _enum = _interopRequireDefault(require("enum"));

var MLConfigImageType = new _enum.default({
  "LOGO_A": "logoA",
  "LOGO_B": "logoB",
  "FEATURE_IMAGE": "featureImage"
});
exports.MLConfigImageType = MLConfigImageType;