"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SchemaOrgDataTypes = void 0;

var _enum = _interopRequireDefault(require("enum"));

var SchemaOrgDataTypes = new _enum.default(['SITE', 'ROOM', 'OFFER', 'MEDIA', 'WEB_PAGE', 'DETAILS_PAGE', 'WEBSITE', 'LIST']);
exports.SchemaOrgDataTypes = SchemaOrgDataTypes;