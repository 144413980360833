"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticProperties = void 0;

var _enum = _interopRequireDefault(require("enum"));

var AnalyticProperties = new _enum.default({
  'EVENT_CATEGORY': 'event_category',
  'EVENT_LABEL': 'event_label',
  'EVENT_VALUE': 'value',
  'PAGE_TITLE': 'page_title',
  'PAGE_PATH': 'page_path',
  'PAGE_LOCATION': 'page_location'
});
exports.AnalyticProperties = AnalyticProperties;