"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = exports.remove = exports.get = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

/**
 * This is a wrapper for js-cookie.js
 */

/**
 * Gets the value of the cookie corresponding to the name
 * @param {String} name, the name of the cookie
 * @returns {Object} The value stored in the cookie
 */
var get = function get(name) {
  return _jsCookie.default.get(name);
};
/**
 * Sets the value of a cookie corresponding to the name
 * @param {String} name the name of the cookie
 * @param {Object} value the value of the cookie
 * @param {int|Date} expiry the time before the cookie expires
 * @param {String} path where the cookie is visible
 */


exports.get = get;

var set = function set(name, value, expiry, path, domain) {
  var options = {};

  if (expiry) {
    options.expires = expiry;
  }

  if (path) {
    options.path = path;
  }

  if (domain) {
    options.domain = domain;
  }

  _jsCookie.default.set(name, value, options);
};
/**
 * Deletes the cookie corresponding to the name
 * @param {String} name the name of the cookie
 */


exports.set = set;

var remove = function remove(name, path, domain) {
  var options = {};

  if (path) {
    options.path = path;
  }

  if (domain) {
    options.domain = domain;
  }

  _jsCookie.default.remove(name, options);
};

exports.remove = remove;