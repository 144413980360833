"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageUsage = void 0;

var _enum = _interopRequireDefault(require("enum"));

var ImageUsage = new _enum.default({
  'UNKNOWN': 'UNKNOWN',
  'ACCOUNT_LOGO': 'ACCOUNT_LOGO',
  'HOTEL_LOGO': 'HOTEL_VIEW_LOGO',
  'FEATURE': 'FEATURE',
  'OTHER': 'OTHER'
});
exports.ImageUsage = ImageUsage;