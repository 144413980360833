"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppNames = void 0;

var _enum = _interopRequireDefault(require("enum"));

var AppNames = new _enum.default({
  "MEDIA_LIBRARY": 'mediaLibrary',
  "MANGO": 'mango'
});
exports.AppNames = AppNames;